const CONFIG = {
  'nsw24': {
    contactEmail: 'campaign@nsw.greens.org.au',
    authorisation: 'Authorised by A. Croft, The Greens NSW, 19a/1 Hordern Place Camperdown, NSW 2040',
    taglines: {
      prepoll: 'Early voting runs between Saturday 7 September and Friday 13 September (except Sunday 8 September)',
      pollingday: 'The NSW Local Government elections are on Saturday 14 September',
      boothselection: 'Select the location where you would like to volunteer',
      reviewchoices: "After you submit this form, your shift will be reviewed. Once confirmed, you'll receive an email that will include all the details of your shift."
    },
    multiplecandidates: false,
    electiondate: '2024-09-14',
    electionname: 'NSW Local Government elections',
    logoURL: 'https://greens.org.au/sites/default/files/logo-nsw.png',
    geocentre: {
      lat: -33.8703,
      lng: 151.2089,
    }
  },
  'nt24': {
    contactEmail: 'office@nt.greens.org.au',
    authorisation: 'Authorised by Jonathan Parry, NT Greens, 15 Somerville Gardens, Parap NT 0820',
    taglines: {
      prepoll: 'Early voting runs between Monday 12 August and Friday 23 August 2024 (except Sunday 18 August)',
      pollingday: 'The NT Territory election is on Saturday 24th August 2024',
      boothselection: 'Select the location where you would like to volunteer',
      reviewchoices: "After you submit this form, your shift will be reviewed. Once confirmed, you'll receive an email that will include all the details of your shift."
    },
    multiplecandidates: false,
    electiondate: '2024-08-24',
    electionname: 'NT Territory Election 2024',
    logoURL: 'https://greens.org.au/sites/default/files/logo-nt.png',
    geocentre: {
      lat: -19.383333,
      lng: 133.357777
    }
  },
  'qldlga24': {
    contactEmail: 'campaign@qld.greens.org.au',
    authorisation: 'Authorised by K. Carra, Queensland Greens, 47 Park Road, Milton 4064',
    taglines: {
      prepoll: 'Early voting runs between Monday 4 March and Friday 15 March (excluding weekends)',
      pollingday: 'The QLD council election is on Saturday 16th March',
      boothselection: 'Select the location where you would like to volunteer',
      reviewchoices: "After you submit this form, your shift will be reviewed. Once confirmed, you'll receive an email that will include all the details of your shift."
    },
    multiplecandidates: false,
    electiondate: '2024-03-16',
    electionname: 'QLD Local Government elections',
    logoURL: 'https://greens.org.au/sites/default/files/logo-qld.png',
    geocentre: {
      lat: -27.4705,
      lng: 153.0260,
    }
  },
  'yes23': {
    contactEmail: 'campaign@sa.greens.org.au',
    authorisation: 'Authorised by E. Pringle, The Greens, 7/291 Angas Street, Adelaide SA 5000',
    taglines: {
      prepoll: 'Early voting starts Tuesday October 3rd',
      pollingday: 'The Yes23 Referendum is on Saturday 14th October',
      boothselection: 'Select the location where you would like to volunteer',
      reviewchoices: "After you submit this form, your shift will be reviewed. Once confirmed, you'll receive an email that will include all the details of your shift."
    },
    multiplecandidates: false,
    electiondate: '2023-10-14',
    electionname: 'Yes23 Referendum',
    logoURL: 'https://greens.org.au/sites/default/files/logo-sa.png',
    geocentre: {
      lat: -34.9285,
      lng: 138.6007,
    }
  },
  'nsw23': {
    contactEmail: 'campaign@nsw.greens.org.au',
    authorisation: 'Authorised by D. Hayden, The Greens NSW, 19a/1 Hordern Place Camperdown NSW 2050',
    taglines: {
      prepoll: 'Early voting runs 18-24 March',
      pollingday: 'The NSW election is on Saturday 25th March',
      boothselection: 'Select the location where you would like to volunteer',
      reviewchoices: "After you submit this form, your shift will be reviewed. Once confirmed, you'll receive an email that will include all the details of your shift."
    },
    multiplecandidates: false,
    electiondate: '2023-03-25',
    electionname: 'NSW State Election',
    logoURL: 'https://greens.org.au/sites/default/files/logo-nsw.png',
    geocentre: {
      lat: -33.8703,
      lng: 151.2089,
    }
  },
  'fed22': {
    contactEmail: {
      'ag': 'volunteer@greens.org.au',
      'act': 'campaign@act.greens.org.au',
      'nsw': 'campaign@nsw.greens.org.au',
      'nt': 'office@nt.greens.org.au',
      'qld': 'roster@qld.greens.org.au',
      'sa': 'campaign@sa.greens.org.au',
      'tas': 'volunteer@tas.greens.org.au',
      'vic': 'boothcoordinator@vic.greens.org.au',
      'wa': 'meisha.bull@wa.greens.org.au'
    },
    authorisation: 'Authorised by Matt Roberts & Willisa Osburn, The Australian Greens, 2/18 Lonsdale Street, Braddon ACT 2612',
    taglines: {
      prepoll: 'Early voting dates are yet to be confirmed',
      pollingday: 'The federal election is on Saturday 21st May',
      boothselection: 'Select the location where you would like to volunteer.',
      reviewchoices: "After you submit this form, your shift will be reviewed. Once confirmed, you'll receive an email that will include all the details of your shift."
    },
    multiplecandidates: false,
    electiondate: '2022-05-21',
    electionname: 'Federal Election',
    logoURL: 'https://greens.org.au/sites/default/files/logo-ag.png',
    geocentre: {
      lat: -30.058333,
      lng: 135.7611446,
    }
  },
  'nswlg21': {
    contactEmail: 'volunteer@nsw.greens.org.au',
    authorisation: 'Authorised by Sylvia Hale, The Greens NSW, Suite D, 263-279 Broadway Glebe NSW 2037',
    taglines: {
      prepoll: '',
      pollingday: 'The NSW local government election is on Saturday 4th September',
      boothselection: "Here's where we need you the most. Choose where you prefer to help",
      reviewchoices: ''
    },
    multiplecandidates: true,
    electiondate: '2021-09-04',
    electionname: 'NSW Local Government Elections',
    logoURL: 'https://greens.org.au/sites/default/files/logo-nsw.png'
  },
  'sablack24': {
    contactEmail: 'campaign@sa.greens.org.au',
    authorisation: 'Authorised by A. Wheaton, The Greens, 7/291 Angus St, Adelaide SA 5000',
    taglines: {
      prepoll: 'Early voting starts Monday November 11th',
      pollingday: 'The Black by-election is on Saturday November 16th',
      boothselection: 'Select the location where you would like to volunteer',
      reviewchoices: "After you submit this form, your shift will be reviewed. Once confirmed, you'll receive an email that will include all the details of your shift.",
    },
    electiondate: '2024-11-16',
    electionname: 'Black by-election',
    logoURL: 'https://greens.org.au/sites/default/files/logo-sa.png',
    geocentre: {
      lat: -34.9285,
      lng: 138.6007,
    }
  }
};

export default CONFIG['sablack24'];
