import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import CONFIG from './config';
import EnterYourAddress from './EnterYourAddress';

export default function ConfirmYourDetails({ firstName, suburbName, electionName, mode, contactEmail, userLocation, setUserLocation }) {
  console.log(userLocation);
  const email = (CONFIG.contactEmail[contactEmail]) ? CONFIG.contactEmail[contactEmail] : CONFIG.contactEmail['ag'];
  return (
    <React.Fragment>
      <Typography paragraph>Hi there <b>{firstName}</b>! Using this tool you can volunteer to hand out how to vote cards at a polling place near you.</Typography>
      {userLocation.lat && userLocation.lat !== "0" ? (<React.Fragment>{suburbName && !userLocation.manual && <Typography paragraph>Our records show you live in <b>{suburbName}</b>.</Typography>}
      {userLocation.manual && <Typography paragraph>Great, we've got your address.</Typography> }
      <Typography paragraph>Click <b>NEXT</b> to see polling places near you.</Typography>
      {suburbName && !userLocation.manual && <Typography paragraph>Not in <b>{suburbName}</b> {mode === "prepoll" ? 'this election' : 'on election day'}? <Link style={{cursor: 'pointer'}} onClick={() => setUserLocation({})}>Click here to enter a different address.</Link></Typography>}</React.Fragment>) : (
      <React.Fragment>
        <Typography paragraph>Select your address and we'll find polling places near you.</Typography><EnterYourAddress setUserLocation={setUserLocation}/>
      </React.Fragment>
      )}
      <Typography paragraph>If you encounter any problems, please email us at <Link href={`mailto:${email}`}>{email}</Link> to let us know you're interested in volunteering and we'll get in touch.</Typography>
    </React.Fragment>
  );
}
