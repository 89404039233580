import { Mapbox } from "../client/Mapbox";

export function getBoothMapImage(booth: any) {
  return Mapbox
    .getStaticImage({
      ownerId: 'mapbox',
      styleId: 'streets-v11',
      width: 500,
      height: 300,
      position: {
        coordinates: [parseFloat(booth.longitude), parseFloat(booth.latitude)],
        zoom: 15
      },
      overlays: [
        {
          marker: {
            coordinates: [parseFloat(booth.longitude), parseFloat(booth.latitude)]
          }
        }
      ]
    }).url();
}
