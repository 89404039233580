import React from "react";
import Link from "@material-ui/core/Link";
import Alert from "react-bootstrap/Alert";
import CONFIG from './config';

export function somethingWentWrong(contactEmail): string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal {
  let email = (CONFIG.contactEmail[contactEmail]) ? CONFIG.contactEmail[contactEmail] : CONFIG.contactEmail['ag'];
  return (
    <Alert variant="danger">
      <b>Uh oh - something went wrong!</b><br />
      Please try again by clicking the link in the email you received, or contact <Link href={`mailto:${email}}`}>{email}</Link>.
    </Alert>
  );
}
