import React from "react";
import Typography from "@material-ui/core/Typography";
import CONFIG from './config';

export default function Authorisation() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {CONFIG.authorisation}
    </Typography>
  );
}
