import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { getBoothMapImage } from './util/getBoothMapImage';
import { Booth } from './types/Booth';

interface BoothCardProps {
  booth: Booth;
  selectedBooth?: string;
  selectBooth: (boothName: string) => void;
}

export default function BoothCard({ booth, selectedBooth, selectBooth}: BoothCardProps) {
  const mapImage = getBoothMapImage(booth);
  return (
    <Grid item sm={6} xs={12}>
      <Card>
        <CardMedia style={{ height: 300 }} image={mapImage} />
        <CardContent>
          <Typography component="h2">{booth.name}</Typography>
          <Typography component="p">
            {booth.prepoll === "0" && booth.name !== booth.premises && `${booth.premises}, `}{booth.address1 + (booth.address2 ? ', ' + booth.address2 : '') + ', ' + booth.address_suburb}<br />
            {/* {boothDistances.length > 0 ? `${Math.round(boothDistances[index+1]/60)} minute walk` : ''} */}
          </Typography>
          <Grid container justify="flex-end">
            <Button variant="contained" color={selectedBooth === booth.name ? "secondary" : "primary"} onClick={() => selectBooth(booth.name)}>{selectedBooth === booth.name ? "Selected" : "Select"}</Button>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
