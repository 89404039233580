import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import CONFIG from './config';

const electionDate = new Date(CONFIG.electiondate);

function ConfirmationDialogRaw(props) {
  const { day, onClose, open, booths, ...other } = props;
  const [state, setState] = React.useState({
    morning: false,
    lunch: false,
    afternoon: false,
  });
  const radioGroupRef = React.useRef(null);
  React.useEffect(() => {
    if (!open) {
      setState({
        morning: false,
        lunch: false,
        afternoon: false,
      });
    }
  }, [day, open]);


  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(state);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  

  const { morning, lunch, afternoon } = state;

  if (!day) return null;

  const error = [morning, lunch, afternoon].filter((v) => v).length !== 2;

  const date = new Date(electionDate.getTime() - 1000 * 60 * 60 * 24 * day);
  const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  const booth = booths.find((booth) => booth.prepoll === day);
  
  const morningStartRaw = booth.info2.split(' - ')[0];
  const morningStartBits = morningStartRaw.split(':');
  const morningStart = 0 + (parseInt(morningStartBits[0]) * 60) + parseInt(morningStartBits[1]);
  const lunchStart = 720;
  const lunchEnd = 840;
  const afternoonEndRaw = booth.info2.split(' - ')[1];
  const afternoonEndBits = afternoonEndRaw.split(':');
  const afternoonEnd = 0 + (parseInt(afternoonEndBits[0]) * 60) + parseInt(afternoonEndBits[1]);


  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">What times on <span style={{ whiteSpace: 'nowrap' }}>{date.toLocaleDateString('en-au', dateOptions).replace(' 2021', '')}</span>?</DialogTitle>
      <DialogContent dividers>
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
              control={<Checkbox checked={morning} onChange={handleChange} name="morning" />}
              label={`Morning (${morningStartRaw} - 12:00)`}
          />
          <FormControlLabel
              control={<Checkbox checked={lunch} onChange={handleChange} name="lunch" />}
              label={`Lunch (12:00 - 14:00)`}
          />
          <FormControlLabel
              control={<Checkbox checked={afternoon} onChange={handleChange} name="afternoon" />}
              label={`Afternoon (14:00 - ${afternoonEndRaw})`}
          />
        </FormGroup>
        <FormHelperText>Tick as many times as you like</FormHelperText>
      </FormControl>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} variant="contained">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary" variant="contained">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}


export default function ChooseShifts({ booths, selectedShifts, setSelectedShifts }) {
  const [activeDay, setActiveDay] = useState(null);
  const handleDialogClose = (dialogState) => {
    setActiveDay(null);
    const newShifts = [];
    if (!dialogState) {
      return;
    }
    if (dialogState.morning) {
      newShifts.push({
        day: activeDay,
        time: 'morning'
      })
    }
    if (dialogState.lunch) {
      newShifts.push({
        day: activeDay,
        time: 'lunch'
      })
    }
    if (dialogState.afternoon) {
      newShifts.push({
        day: activeDay,
        time: 'afternoon'
      })
    }
    setSelectedShifts(selectedShifts.concat(newShifts));
  }
  return (
    <React.Fragment>
        <ConfirmationDialogRaw
        keepMounted
        open={activeDay}
        onClose={handleDialogClose}
        day={activeDay}
        booths={booths}
        />
      <Typography>What days would you like to hand out at <b>{booths[0].name}?</b><br />You can select as many as you like.</Typography>
      <List>
        <Grid container>
          {booths.map((day) => {
            const date = new Date(electionDate.getTime() - 1000 * 60 * 60 * 24 * day.prepoll);
            const dateOptions = {  weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            return (
              <Grid item sm={3} xs={12}>
                <ListItem key={day.prepoll} role={undefined} dense button onClick={() => {
                  if (selectedShifts.some((shift) => shift.day === day.prepoll)) {
                    setSelectedShifts(selectedShifts.filter((shift) => shift.day !== day.prepoll));
                  } else {
                    setActiveDay(day.prepoll)
                  }
                }
                }>
                  <ListItemIcon style={{minWidth: '30px'}}>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                      checked={selectedShifts.some((shift) => shift.day === day.prepoll)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={date.toLocaleDateString('en-au', dateOptions).replace(' 2021', '')} secondary={selectedShifts.filter((shift) => shift.day === day.prepoll).map((shift) => shift.time).join(', ')} />
                </ListItem>
              </Grid>
            )
          })}
          </Grid>
      </List>
    </React.Fragment>
  );
}