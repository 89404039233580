import React from 'react';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import CONFIG from './config';
import { convertMinsToHrsMins } from './util/convertMinsToHrsMins';

const electionDate = new Date(CONFIG.electiondate);

export default function ConfirmYourDetails({ firstName, selectedBooth, selectedShifts, slots, booths, needTalkStatus, setNeedTalkStatus, willTravelStatus, setWillTravelStatus, mode }) {

  const selectedBoothDetails = booths.find((booth) => booth.id === slots[0].pollingBoothId);

  return (
    <React.Fragment>
      <Typography>Hi <b>{firstName}</b>,</Typography>
      <Typography>Please check the details below are correct, then click <b>SUBMIT</b>.</Typography>
      <Paper style={{paddingLeft: '8px'}} variant="outlined">
        <p><b>{mode === "prepoll" ? "Early Voting Place" : "Polling Centre"}:</b> {selectedBoothDetails.name} - {selectedBoothDetails.premises}</p>
        <p><b>{mode === "prepoll" ? "Day(s)" : "Times"}:</b><br />
          <ul>
            {slots.sort((a, b) => { const boothA = booths.find((booth) => booth.id === a.pollingBoothId); const boothB = booths.find((booth) => booth.id === b.pollingBoothId); return boothB.prepoll - boothA.prepoll }).map((slot) => {
              const booth = booths.find((booth) => booth.id === slot.pollingBoothId);
              const date = new Date(electionDate.getTime() - 1000 * 60 * 60 * 24 * parseInt(booth.prepoll));
              const dateOptions = {  weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
              return (<li><b>{date.toLocaleDateString('en-au', dateOptions).replace(' 2021', '')}: </b>{convertMinsToHrsMins(parseInt(slot.start))} - {convertMinsToHrsMins(parseInt(slot.end))}</li>)
            })}
            {/* {Object.keys(groupByDay(selectedShifts)).map((day) => {
              const shifts = groupByDay(selectedShifts)[day];
              const date = new Date(electionDate.getTime() - 1000 * 60 * 60 * 24 * parseInt(day));
              const dateOptions = {  weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
              return <li>{date.toLocaleDateString('en-au', dateOptions) }: {shifts.join(', ')}</li>
            })} */}
          </ul>
          <i>{CONFIG.taglines.reviewchoices}</i>
        </p>
      </Paper>
      <FormGroup row>
      <FormControlLabel
        control={<Checkbox checked={needTalkStatus} onChange={(ev) => setNeedTalkStatus(ev.target.checked)} name="needTalkStatus" />}
        label="I need to talk to someone about my shift"
        />
        <FormControlLabel
        control={<Checkbox checked={willTravelStatus} onChange={(ev) => setWillTravelStatus(ev.target.checked)} name="willTravelStatus" />}
        label="I can travel to another booth if needed"
        />
      </FormGroup>
    </React.Fragment>
  );
}