import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import { useStyles } from "./useStyles";

interface SelfRosterStepperProps {
  activeStep: number;
  steps: string[];
  isButtonDisabled: (mode: any) => boolean;
  mode: string;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
}

export function SelfRosterStepper({ activeStep, steps, isButtonDisabled, mode, setActiveStep }: SelfRosterStepperProps) {
  const classes = useStyles();
  return (
    <Stepper
      activeStep={activeStep}
      className={classes.stepper}
      alternativeLabel
      nonLinear
    >
      {steps.map((label, index) => (
        <Step key={label}>
          <StepButton completed={activeStep > index} disabled={!(index < activeStep || (index === activeStep + 1 && !isButtonDisabled(mode)))} onClick={() => setActiveStep(index)}>
            <StepLabel>{label}</StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
}
