
export const groupByDay = (shifts) => {
  const days = {};
  shifts.forEach((shift) => {
    if (!days[shift.day.toString()]) {
      days[shift.day.toString()] = [];
    }
    days[shift.day.toString()].push(shift.time);
  });
  return days;
};
