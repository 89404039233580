import { Booth } from "../types/Booth";
import { ShiftTime } from "../types/ShiftTime";

export const boothIsFull = (booth: Booth, times: ShiftTime[]): boolean => {
  let answer = false;
  let mapping = {
    'morning': [480, 720],
    'lunch': [720, 840],
    'afternoon': [840, 1080]
  };
  times.forEach((time) => {
    let start = mapping[time][0];
    let end = mapping[time][1];
    console.log({ time, start, end, booth });
    let minutesForShift = booth.slots.map((slot) => {
      slot.timestart = typeof slot.timestart === 'string' ? parseInt(slot.timestart) : slot.timestart;
      slot.timeend = typeof slot.timeend === 'string' ? parseInt(slot.timeend) : slot.timeend;
      if (slot.timestart > end || slot.timeend < start) {
        return 0;
      }
      let slotStart = (slot.timestart >= start ? slot.timestart : start);
      let slotEnd = (slot.timeend <= end ? slot.timeend : end);
      let minutesInsideShft = slotEnd - slotStart;
      return minutesInsideShft;
    }).reduce((a, v) => a + v, 0);
    console.log(minutesForShift);
    let desiredMinutesForShift = parseInt(booth.people_required_calculated) * 60;
    if (minutesForShift >= desiredMinutesForShift) {
      answer = true;
    }
  });
  return answer;
};
