import { groupByDay } from "./groupByDay";

export const createSlots = (shifts, booths, selectedBooth, selectedTimes, mode) => {
  const slots = [];
  const days = mode === "prepoll" ? groupByDay(shifts) : { "0": selectedTimes };
  console.log(days);
  Object.keys(days).forEach((dayNumber) => {
    const day = days[dayNumber];
    console.log(day);
    console.log(booths);
    console.log(selectedBooth);
    const booth = booths.find(
      (booth) => booth.name === selectedBooth && booth.prepoll === dayNumber
    );
    console.log(booth);
    const slot = {
      pollingBoothId: booth.id,
      start: undefined,
      end: undefined,
    };
    const morningStartBits = booth.info2?.split(" - ")[0].split(":");
    const morningStart = mode === "prepoll" ?
      0 + parseInt(morningStartBits[0]) * 60 + parseInt(morningStartBits[1]) : 480;
    const lunchStart = 720;
    const lunchEnd = 840;
    const afternoonEndBits = booth.info2?.split(" - ")[1].split(":");
    const afternoonEnd = mode === "prepoll" ?
      0 + parseInt(afternoonEndBits[0]) * 60 + parseInt(afternoonEndBits[1]) : 1080;
    if (day.includes("morning") &&
      !day.includes("lunch") &&
      day.includes("afternoon")) {
      // Special case of non-contiguous shifts
      slots.push({
        pollingBoothId: booth.id,
        start: morningStart,
        end: lunchStart,
      });
      slots.push({
        pollingBoothId: booth.id,
        start: lunchEnd,
        end: afternoonEnd,
      });
      return;
    }
    if (day.includes("morning")) {
      slot.start = morningStart;
    } else if (day.includes("lunch")) {
      slot.start = lunchStart;
    } else if (day.includes("afternoon")) {
      slot.start = lunchEnd;
    }
    if (day.includes("afternoon")) {
      slot.end = afternoonEnd;
    } else if (day.includes("lunch")) {
      slot.end = lunchEnd;
    } else if (day.includes("morning")) {
      slot.end = lunchStart;
    }
    slots.push(slot);
  });
  return slots;
};
