import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CONFIG from './config';
import { useStyles } from "./useStyles";

export function Header() {
  const classes = useStyles();
  return (
    <AppBar position="absolute" color="default" className={classes.appBar}>
      <Toolbar>
        <img src={CONFIG.logoURL} alt="Greens logo" style={{ padding: '10px 0', height: '90px' }} />
        <Typography variant="h6" style={{ fontFamily: '"Roboto Condensed", Helvetica, Arial, sans-serif', fontWeight: 700, color: "white", textTransform: "uppercase" }} noWrap>
          Volunteer with The Greens
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
