import React from "react";
import Typography from "@material-ui/core/Typography";
import CONFIG from './config';
import { SelfRosterMode } from "./types/SelfRosterMode";

export function SelfRosterHeader({ mode }: { mode: SelfRosterMode }) {
  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center">
        Volunteer to hand out {mode === "prepoll" ? "for early voting" : "on election day"}
      </Typography>
      <Typography component="h2" variant="h5" align="center">
        {mode === "prepoll" ? CONFIG.taglines.prepoll : CONFIG.taglines.pollingday}
      </Typography>
    </React.Fragment>
  );
}
