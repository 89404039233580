// TODO: Shared election logic between volunteer & htv
export const groupBoothsByDay = (booths) => {
  if (!booths) {
    return {};
  }
  const prepollBooths = {};
  booths.forEach((booth) => {
    if (!prepollBooths[booth.name]) {
      prepollBooths[booth.name] = {
        ...booth,
        days: [],
      };
    }
    const day = {
      daysFromElection: parseInt(booth.prepoll, 10),
      times: undefined,
    };
    if (booth.booth_time) {
      day.times = booth.booth_time;
    }
    if (prepollBooths[booth.name].days) {
      prepollBooths[booth.name].days.push(day);
    }
  });
  return prepollBooths;
};
