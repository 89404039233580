import React, { useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CONFIG from './config';
import BoothCard from './BoothCard';

export default function ChooseAPollingPlace({ booths, selectedBooth, selectBooth, mode }) {
  return (
    <React.Fragment>
      <Typography paragraph>{CONFIG.taglines.boothselection}</Typography>
      <Grid container spacing={2}>
        {Object.keys(booths).splice(0, 4).map((boothName) =>
        (
          <BoothCard booth={booths[boothName]} selectedBooth={selectedBooth} selectBooth={selectBooth} />
        )
        )}
      </Grid>
    </React.Fragment>
  );
}



