import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';


export default function ChooseTimes({ selectedTimes, setSelectedTimes }) {
  const handleChange = (event) => {
    if (event.target.checked) {
      setSelectedTimes(selectedTimes.concat([event.target.name]));
    } else {
      setSelectedTimes(selectedTimes.filter((s) => s !== event.target.name));
    }
  };
  return (
    <React.Fragment>
      <Typography>What times would you like to volunteer? Select as many as you like.</Typography>
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
              control={<Checkbox checked={selectedTimes.includes("morning")} onChange={handleChange} name="morning" />}
              label={`Morning (08:00 - 12:00)`}
          />
          <FormControlLabel
              control={<Checkbox checked={selectedTimes.includes("lunch")} onChange={handleChange} name="lunch" />}
              label={`Lunch (12:00 - 14:00)`}
          />
          <FormControlLabel
              control={<Checkbox checked={selectedTimes.includes("afternoon")} onChange={handleChange} name="afternoon" />}
              label={`Afternoon (14:00 - 18:00)`}
          />
        </FormGroup>
      </FormControl>
    </React.Fragment>
  );
}